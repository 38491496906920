/* eslint-disable react/no-danger */
import { h, Fragment } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';
import { useAppContext } from '@/state/store';
import CookieStorage from '@/services/cookie-storage';
import { firstUserInteractionListener } from '@/helpers/userInteraction';
import { sendGA4ElementEvent, sendOnClickGA4 } from '@/helpers/ga4';
import yieldToMain from '@/helpers/yieldToMain';
import MarketingPromoControllFactory from '@/factories/marketingPromoControll';
import Timed from '@/components/Ads/TimedPopups/TimedDefault/Timed';

const ScheduledCancellation = lazy(() => import(/* webpackChunkName: "TimedScheduledCancellation" */ '../../components/Ads/TimedPopups/ScheduledCancellation/ScheduledCancellation'));
const Suspension = lazy(() => import(/* webpackChunkName: "TimedSuspension" */ '../../components/Ads/TimedPopups/Suspension/Suspension'));
const MarketingPromo = lazy(() => import(/* webpackChunkName: "TimedMarketingPromo" */ '../../components/Ads/TimedPopups/MarketingPromo/MarketingPromo'));
const TimedSegmentedOffer = lazy(() => import(/* webpackChunkName: "TimedSegmentedOffer" */ '../../components/Ads/TimedPopups/SegmentedOffer/TimedSegmentedOffer'));

interface TimedControllerProps {
  hasScheduledCancellation?: string;
  cancellationDate?: string;
  userPlan?: string;
  isSuspendedUser?: string;
  variant?: string;
  loggedOutDesktop?: boolean;
  isloggedOut?: boolean;
}

const adWaitTime = 15 * 60 * 1000;
const refreshTime = 30 * 1000;
const cookieStorage = new CookieStorage();
const mktPromo = MarketingPromoControllFactory.make();
const isMarketingPromo = mktPromo ? mktPromo.mktPromoIsActive() : false;

const TimedController = ({
  hasScheduledCancellation = '0',
  cancellationDate = '',
  userPlan = '',
  isSuspendedUser = '0',
  variant = '',
  loggedOutDesktop,
  isloggedOut
}: TimedControllerProps) => {
  const [state, dispatch] = useAppContext();
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const [timedInterval, setTimedInterval] = useState<NodeJS.Timer>(null);
  const [offerHasExpiredOverTime, setOfferHasExpiredOverTime] = useState<boolean>(false);
  const segmentedOfferTimer = null;
  const showSegmentedOffer = false;

  const handleOnAccept = (adId: string, okButtonText: string) => async () => {
    window.requestAnimationFrame(async () => setSelectedAdId(null));
    await yieldToMain();
    let targetUrl = '';
    if (adId === 'scheduled-cancellation') {
      targetUrl = process.env.GIVE_UP_CANCELLATION_URL;
      await yieldToMain();
    } else if (adId === 'suspension') {
      targetUrl = process.env.UPGRADE_URL;
      await yieldToMain();
    } else if (adId === 'segmented-offer') {
      targetUrl = `${process.env.UPGRADE_URL}?cp=bsc`;
      sendGA4ElementEvent('modal-time-reg-p2_button');
      await yieldToMain();
    } else {
      targetUrl =
        typeof window.visitAdvantagePro !== 'undefined'
          ? process.env.PRO_ADVANTAGES_URL
          : process.env.PAYMENT_URL;
      await yieldToMain();
      sendOnClickGA4(adId);
      await yieldToMain();
    }
    dispatch({
      type: 'MODAL_AD_ACCEPTED',
      payload: {
        adId,
        targetUrl,
      },
    });
    await yieldToMain();
  };

  const handleOnClose = (adId: string, buttonText?: string) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();

    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await yieldToMain();
  };

  const getModalAdId = (): string => {
    if (hasScheduledCancellation === '1') {
      return 'scheduled-cancellation';
    }

    if (isSuspendedUser === '1') {
      return 'suspension';
    }

    if (isMarketingPromo) {
      return 'marketing-promo';
    }

    if (showSegmentedOffer && !offerHasExpiredOverTime) {
      return 'segmented-offer';
    }

    if (isloggedOut) {
      return 'normal';``
    }

    return null;
  };

  const handleExpiredOffer = () => {
    setOfferHasExpiredOverTime(true);
   }

  const showTimedAd = async () => {
    const dateNow = Date.now();
    const defaultDate = cookieStorage.get('mktadsapp-tour')
      ? dateNow
      : dateNow - adWaitTime;
    await yieldToMain();
    const showTime = Number(
      window.localStorage.getItem('mktadsapp-timed-ad-showtime') || defaultDate
    );
    await yieldToMain();

    const elapsedTime = dateNow - showTime;

    if (elapsedTime >= adWaitTime) {
      const adId = getModalAdId();
      window.requestAnimationFrame(async () => {
        setSelectedAdId(adId);
      })
      await yieldToMain();

      setTimeout(() => {
        window.localStorage.setItem(
          'mktadsapp-timed-ad-showtime',
          String(dateNow)
        );
      }, 1000);

      if (!state.loadedAdId) {
        dispatch({
          type: 'MODAL_AD_OPENED',
          payload: {
            adId,
            handleTime: dateNow,
            modalType: 'timed',
          },
        });
      }
    }
  };

  const handleTimedAd = () => {
    if (state.originUrl.match(/area-candidato|apply=true/)) {
      return;
    }

    if (timedInterval) {
      clearInterval(timedInterval);
    }

    setTimeout(() => {
      showTimedAd();
    }, 2000);

    const newInterval = setInterval(() => {
      showTimedAd();
    }, refreshTime);

    setTimedInterval(newInterval);
  };

  useEffect(() => {
    firstUserInteractionListener(handleTimedAd);
  }, []);

  let modal = <Fragment />;

  switch (selectedAdId) {
    case 'normal':
      modal = (
        <Timed
          adId={selectedAdId}
          handleOnAccept={handleOnAccept}
          handleOnClose={handleOnClose}
          loggedOutDesktop={loggedOutDesktop}
        />
      );
      break;
    case 'scheduled-cancellation':
      modal = (
        <Suspense fallback={<div></div>}>
          <ScheduledCancellation
            adId={selectedAdId}
            handleOnAccept={handleOnAccept}
            handleOnClose={handleOnClose}
            userPlan={userPlan}
            cancellationDate={cancellationDate}
          />
        </Suspense>
      );
      break;
    case 'suspension':
      modal = (
        <Suspense fallback={<div></div>}>
          <Suspension
            adId={selectedAdId}
            handleOnAccept={handleOnAccept}
            handleOnClose={handleOnClose}
            variant={variant}
          />
        </Suspense>
      );
      break;
    case 'marketing-promo':
      modal = (
        <Suspense fallback={<div></div>}>
          <MarketingPromo
            adId={selectedAdId}
            handleOnAccept={handleOnAccept}
            handleOnClose={handleOnClose}
            mktPromo={mktPromo}
            loggedOutDesktop={loggedOutDesktop}
          />
        </Suspense>
      );
      break;
    case 'segmented-offer':
      modal = (
        <Suspense fallback={<div></div>}>
          <TimedSegmentedOffer
            adId={selectedAdId}
            handleOnClose={handleOnClose}
            handleOnAccept={handleOnAccept}
            segmentedOfferTimer={segmentedOfferTimer}
            onOfferExpires={handleExpiredOffer}
          />
        </Suspense>
      );
      break;
  }

  return modal;
};

export default TimedController;
