export interface IState {
  applyAdId?: string;
  candidateId?: number;
  jobId?: number;
  avatarUrl?: string;
  openedAdModalType?: string;
  loadedAdId?: string;
  acceptedAdId?: string;
  closedAdId?: string;
  jobApplies?: number;
  targetUrl: string;
  originUrl: string;
  handleTime?: number;
  loadTime?: number;
  endTime?: number;
  rankPosition?: any;
  bestRankPosition?: any;
  apply?: any;
  applyAbtest?: string;
  loadedAdHash?: string;
  acceptedAdHash?: string;
  loadedJobBannerId?: string;
  acceptedJobBannerId?: string;
  jobBannerInfo?: any;
  loadedBanners?: any;
  acceptedBanners?: any;
  noRedirect?: boolean;
  segmentationInfo?: {
    userCheckoutVisits: number;
  };
}

const initialState: IState = {
  applyAdId: null,
  candidateId: null,
  jobId: null,
  avatarUrl: null,
  openedAdModalType: null,
  loadedAdId: null,
  acceptedAdId: null,
  closedAdId: null,
  jobApplies: null,
  targetUrl: process.env.UPGRADE_URL,
  originUrl: window ? window.location.href : '',
  handleTime: null,
  loadTime: null,
  endTime: null,
  rankPosition: {},
  bestRankPosition: null,
  apply: null,
  applyAbtest: null,
  loadedAdHash: null,
  acceptedAdHash: null,
  loadedJobBannerId: null,
  acceptedJobBannerId: null,
  jobBannerInfo: {},
  loadedBanners: {},
  acceptedBanners: {},
  noRedirect: false,
  segmentationInfo: {
    userCheckoutVisits: null,
  },
};

const reducer = (state, action) => {
  let newState: any = {};

  switch (action.type) {
    case 'APPLY_AD_STARTED':
      return {
        ...state,
        jobId: action.payload.jobId,
        apply: action.payload.apply,
        candidateId: action.payload.candidateId,
        handleTime: action.payload.handleTime,
      };
    case 'APPLY_AD_SELECTED':
      return {
        ...state,
        applyAdId: action.payload.adId,
        rankPosition: action.payload.rankPosition,
      };
    case 'MODAL_AD_STARTED':
      return {
        ...state,
        candidateId: action.payload.candidateId,
      };
    case 'MODAL_AD_OPENED':
      newState = {};
      if (action.payload.candidateId) {
        newState.candidateId = action.payload.candidateId;
      }

      if (action.payload.adId && action.payload.handleTime) {
        newState.handleTime = action.payload.handleTime;
      }

      if (action.payload.jobId) {
        newState.jobId = action.payload.jobId;
      }

      return {
        ...state,
        ...newState,
        openedAdModalType: action.payload.modalType,
        loadTime: null,
        closedAdId: null,
        acceptedAdId: null,
        endTime: null,
      };
    case 'MODAL_AD_LOADED':
      if (state.closedAdId) {
        return {
          ...state,
          loadedAdId: null,
          loadTime: null,
          closedAdId: null,
        };
      }

      return {
        ...state,
        loadedAdId: action.payload.adId,
        acceptedAdId: null,
        closedAdId: null,
        loadTime: Date.now(),
        endTime: Date.now(),
      };
    case 'MODAL_AD_ACCEPTED':
      newState = {
        ...state,
        loadedAdId: null,
        acceptedAdId: action.payload.adId,
        closedAdId: null,
        openedAdModalType: null,
        applyAdId: null,
        handleTime: null,
        endTime: Date.now(),
      };

      if (action.payload.targetUrl) {
        newState.targetUrl = action.payload.targetUrl;
      }

      return {
        ...newState,
      };
    case 'MODAL_AD_CLOSED':
      newState = {};
      if (!state.loadTime) {
        newState.loadTime = state.handleTime;
      }

      return {
        ...state,
        ...newState,
        loadedAdId: null,
        acceptedAdId: null,
        closedAdId: action.payload.adId,
        openedAdModalType: null,
        applyAdId: null,
        handleTime: null,
        endTime: Date.now(),
      };
    case 'JOBAPPLY_TOTAL_FETCHED':
      return {
        ...state,
        jobApplies: action.payload.jobApplies,
      };
    case 'AVATAR_URL_FETCHED':
      return {
        ...state,
        avatarUrl: action.payload.url,
      };
    case 'BANNER_AD_LOADED':
      newState = { loadedBanners: state.loadedBanners };
      newState.loadedBanners[action.payload.adHash] = {
        adId: action.payload.adId,
        position: action.payload.position,
        startTime: Date.now(),
        endTime: Date.now(),
      };

      return {
        ...state,
        ...newState,
        loadedAdHash: action.payload.adHash,
      };
    case 'BANNER_AD_ACCEPTED':
      newState = {
        acceptedBanners: state.acceptedBanners,
        noRedirect: action.payload.noRedirect,
      };
      newState.acceptedBanners[action.payload.adHash] = {
        adId: action.payload.adId,
        startTime: state.loadedBanners[action.payload.adHash].endTime,
        position: state.loadedBanners[action.payload.adHash].position,
        endTime: Date.now(),
      };

      if (action.payload.targetUrl) {
        newState.targetUrl = action.payload.targetUrl;
      }

      return {
        ...state,
        ...newState,
        acceptedAdHash: action.payload.adHash,
      };
    case 'JOBBANNER_AD_STARTED':
      return {
        ...state,
        loadedJobBannerId: null,
        acceptedJobBannerId: null,
        jobBannerInfo: {
          startTime: Date.now(),
          endTime: Date.now(),
        },
      };
    case 'JOBBANNER_AD_LOADED':
      return {
        ...state,
        loadedJobBannerId: action.payload.adId,
        jobBannerInfo: {
          startTime: state.jobBannerInfo.endTime,
          endTime: Date.now(),
        },
      };
    case 'JOBBANNER_AD_ACCEPTED':
      return {
        ...state,
        acceptedJobBannerId: action.payload.adId,
        jobBannerInfo: {
          startTime: state.jobBannerInfo.endTime,
          endTime: Date.now(),
        },
      };
    case 'BEST_RANK_POSITION_SELECTED':
      return {
        ...state,
        bestRankPosition: action.payload.rankPosition,
      };
    default:
      return state;
  }
};

export { initialState, reducer };
