import { FunctionalComponent, h } from 'preact';
import { useEffect, useContext, useState } from 'preact/hooks';
import { sendMetricEvent } from '@/services/metrics';
import { Context } from '@/state/store';
import EventsName from '@/constants/EVENTS_NAME';
import ApplyController from '@/controllers/Apply/ApplyController';
import AutoSendModalsController from '@/controllers/AutoSendModals/AutoSendModalsController';
import PlansModalsController from '@/controllers/PlansModals/PlansModalsController';
import TimedController from '@/controllers/Timed/TimedController';
import SuperApplyModalsController from '@/controllers/SuperApply/ModalsController';
import style from '@/components/Styles/AdsFontFamily.scss';
import CompatibilityModalsController from '@/controllers/CompatibilityModals/CompatibilityModalsController';
import { firstUserInteractionListener } from '@/helpers/userInteraction';
import { fetchUserTestVariant } from '@/services/abTest';
import logger, { logLevels } from '@/services/logger';
import { UserPlan } from '@/types/user';
import { messageProp } from '@/types/message';
import yieldToMain from '@/helpers/yieldToMain';

interface ModalType {
  dataCandidateId: string;
  isPaidUser: string;
  hasScheduledCancellation: string;
  cancellationDate: string;
  userPlan: keyof typeof UserPlan;
  isSuspendedUser: string;
  isAggregateJobFlowOnboarding: string;
  variant: string;
}

const Modal: FunctionalComponent<ModalType> = ({
  dataCandidateId,
  isPaidUser,
  hasScheduledCancellation,
  cancellationDate,
  userPlan,
  isSuspendedUser,
  isAggregateJobFlowOnboarding,
  variant,
}) => {
  const [state, dispatch] = useContext(Context);
  const [messages, setMessages] = useState<messageProp[]>([]);
  const [isFetchingInitialData, setIsFetchingInitialData] =
    useState<boolean>(true);

  const getJobTitle = (jobId: number) => {
    const titleTag = document.querySelector(`article[id="job-${jobId}"] h2 a`);

    return titleTag ? titleTag.textContent : null;
  };


  useEffect(() => {
    async function initialCalls() {
      try {
        const expNameId = process.env.EXPERIMENT_NAME_ID;
        if (expNameId && expNameId === 'xm_rmv_modais') {
          setIsFetchingInitialData(true);
          await yieldToMain();
          const experiment = await fetchUserTestVariant();
          await yieldToMain();
        }
      } catch (error) {
        logger({
          level: logLevels.error,
          message: 'Failed to set test variant',
          data: error,
        });
        await yieldToMain();
      } finally {
        setIsFetchingInitialData(false);
        await yieldToMain();
      }
    }


    firstUserInteractionListener(initialCalls);
  }, []);

  useEffect(() => {
    if (!isFetchingInitialData) {
      setTimeout(() => {
        messages.forEach((evtMessage) => {
          const data = evtMessage.data || {};
          window.postMessage({ ...data }, '*');
        });
      }, 2000);
    }
  }, [isFetchingInitialData]);

  useEffect(() => {
    if (state.loadedAdId) {
      setTimeout(() => {
        sendMetricEvent(
          {
            eventName: EventsName.show_ad,
            adId: state.loadedAdId,
            startTime: state.handleTime,
            endTime: state.endTime,
            apply: state.apply,
            rankPosition: state.rankPosition,
          },
          'loadedAdId'
        );
      }, 0);
    }
  }, [state.loadedAdId]);

  useEffect(() => {
    if (state.acceptedAdId) {
      setTimeout(async () => {
        sendMetricEvent(
          {
            eventName: EventsName.accept_ad,
            adId: state.acceptedAdId,
            startTime: state.loadTime,
            endTime: state.endTime,
            apply: state.apply,
          },
          'acceptedAdId'
        );

        const urlParam = state.targetUrl.includes('?') ? '&' : '?';
        let targetUrl = `${state.targetUrl}${urlParam}adId=${state.acceptedAdId}&jobId=${state.jobId}`;

        const jobTitle = getJobTitle(state.jobId);
        await yieldToMain();

        if (
          (state.acceptedAdId === 'top20StandOutModal' ||
            state.acceptedAdId === 'bigLeapStandOutModal') &&
          !!jobTitle
        ) {
          targetUrl = `${targetUrl}&usrog=tp20bglp&jobTitle=${jobTitle}`;
        }

        window.location.href = targetUrl;
      }, 0);
    }
  }, [state.acceptedAdId]);

  useEffect(() => {
    if (state.closedAdId) {
      setTimeout(async () => {
        sendMetricEvent(
          {
            eventName: EventsName.close_ad,
            adId: state.closedAdId,
            startTime: state.loadTime,
            endTime: state.endTime,
            apply: state.apply,
          },
          'closedAdId'
        );
      }, 0);
    }
  }, [state.closedAdId]);

  useEffect(() => {
    if (dataCandidateId) {
      dispatch({
        type: 'MODAL_AD_STARTED',
        payload: {
          candidateId: dataCandidateId,
        },
      });
    }
  }, []);

  return (
    <section>
      {!isFetchingInitialData && (
        <article className={`${style.ModalWrapper}`}>
          {isPaidUser === '1' && hasScheduledCancellation === '1' && (
            <div>
              <TimedController
                hasScheduledCancellation={hasScheduledCancellation}
                cancellationDate={cancellationDate}
                userPlan={userPlan}
                isSuspendedUser={isSuspendedUser}
              />
            </div>
          )}
          {isPaidUser !== '1' && (
            <div>
              <TimedController
                isSuspendedUser={isSuspendedUser}
                variant={variant}
              />
              <ApplyController userPlan={userPlan} />
              <AutoSendModalsController />
              <PlansModalsController />
              <CompatibilityModalsController />
            </div>
          )}
          <SuperApplyModalsController isPaidUser={isPaidUser} />
        </article>
      )}
    </section>
  );
};

export default Modal;
