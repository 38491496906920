/* eslint-disable react/no-danger */
import { useEffect, useContext, useState } from 'preact/hooks';
import { Context } from '@/state/store';
import { selectApplyAdByRankPosition } from '@/state/action';
import ModalTour from '@/components/ModalTour/ModalTour';
import useTargetPlanName from '@/hooks/useTargetPlanName';
import Modals from '@/components/Ads/ApplyModals';
import {
  closeButtonName,
  title,
  template,
  getCTAButtonName,
} from './ApplyAdLabels';
import { UserPlan } from '@/types/user';
import { sendMetricEvent } from '@/services/metrics';
import yieldToMain from '@/helpers/yieldToMain';

interface ApplyControllerProps {
  userPlan?: keyof typeof UserPlan;
}

const ApplyController = ({ userPlan }: ApplyControllerProps) => {
  const [state, dispatch] = useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [selectedAdId, setSelectedAdId] = useState(null);
  const targetPlanName = useTargetPlanName();
  const applyStandoutList = {
    default: 'defaultStandOutModal',
    top20: 'top20StandOutModal',
    bigLeap: 'bigLeapStandOutModal',
  };

  const allowedAdIds = [...Object.values(applyStandoutList)]
  const allowedEventsName = ['mktadsapp-apply-made'];

  const handleOnAccept = (adId) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_ACCEPTED', payload: { adId } });
    await yieldToMain();
    setLoading(false);
    await yieldToMain();
  };

  const handleOnClose = (adId) => async () => {
    window.requestAnimationFrame(async () => {
      setSelectedAdId(null);
    });
    await yieldToMain();
    dispatch({ type: 'MODAL_AD_CLOSED', payload: { adId } });
    await yieldToMain();
    setLoading(false);
    await yieldToMain();
  };

  const showAdApply = async (payload, handleTime, eventType) => {
    const { jobId } = payload;
    window.requestAnimationFrame(async () => {
      setLoading(true);
    });
    await yieldToMain();
    sendMetricEvent(
      {
        eventName: eventType,
        adId: 'apply-ads',
        meta: {
          ...payload,
          originUrl: state.originUrl,
        },
      },
      'frontSendIARequest'
    );
    await yieldToMain();

    dispatch(
      selectApplyAdByRankPosition({
        jobId,
        apply: payload.applies,
        handleTime,
        standoutList: applyStandoutList,
        eventType,
      })
    );
    await yieldToMain();
  };

  const handleMessage = async (message) => {
    const { data } = message;
    const { eventName, payload } = data || {};
    const newPayload = payload || {};
    const handleTime = Date.now();
    if (eventName === 'mktadsapp-apply-made') {
      showAdApply(newPayload, handleTime, eventName);
      await yieldToMain();
    }

    if ('mktadsapp-apply-made' === eventName) {
      sendMetricEvent(
        {
          eventName,
          adId: 'apply-ads',
          meta: {
            ...payload,
            originUrl: state.originUrl,
          },
        },
        'mktAdsApplyPostMessage'
      );
      await yieldToMain();
    }
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      (eventMessage) => {
        if (eventMessage && allowedEventsName.includes(eventMessage?.data?.eventName || '')) {
          handleMessage(eventMessage);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    const checkApplyAdId = async () =>  {
      if (state.applyAdId != null) {
        window.requestAnimationFrame(async() => {
          dispatch({
            type: 'MODAL_AD_OPENED',
            payload: {
              adId: state.applyAdId,
              modalType: 'apply',
            },
          });
          await yieldToMain();
          dispatch({ type: 'MODAL_AD_LOADED', payload: { adId: state.applyAdId } });
          await yieldToMain();
        });
        await yieldToMain();
      }
    }

    checkApplyAdId();
  }, [state.applyAdId]);

  useEffect(() => {
    const checkLoading = async () => {
      if (state.loadedAdId != null && allowedAdIds.includes(state.loadedAdId)) {
        window.requestAnimationFrame(async() => {
          setSelectedAdId(state.loadedAdId);
           await yieldToMain();
           setLoading(false);
           await yieldToMain();
        });
        await yieldToMain();
      }
    }

    checkLoading();
  }, [state.loadedAdId]);

  const hasCloseButton = isLoading;
  let layout = null;
  let defaultButton = null;
  let extraButton = null;
  let showNextButton = false;
  let modalTitle = null;
  let showTitle = true;
  let customInfoFooterComponent = null;
  let customTitleComponent = null;
  let closeModal = handleOnClose('loader');

  const setAdComponent = async () => {
    if (selectedAdId) {
      const Modal = Modals[selectedAdId];
      showNextButton = true;
      //@ts-ignore
      modalTitle = template(title[selectedAdId], {
        rankPosition: state.rankPosition,
      });
      showTitle = !!modalTitle;
      closeModal = handleOnClose(selectedAdId);
      const ctaButtonName = getCTAButtonName();

      defaultButton = {
        text: closeButtonName[selectedAdId],
        onClick: handleOnClose(selectedAdId),
        skin: 'link',
        origin: 'ApplyController',
      };
      extraButton = {
        text: template(ctaButtonName[selectedAdId], {
          rankPosition: state.rankPosition,
          targetPlanName,
        }),
        onClick: handleOnAccept(selectedAdId),
        skin: 'secondary',
        origin: 'ApplyController',
        selectedAdId,
      };

      layout = (
        <Modal
          adId={selectedAdId}
          userPlan={userPlan}
        />
      );
      await yieldToMain();
    }
  }
  setAdComponent();

  return (
    (isLoading || selectedAdId) && (
      <ModalTour
        id='apply-modal'
        title={modalTitle}
        closeModal={closeModal}
        showNextButton={showNextButton}
        extraButton={extraButton}
        hasCloseButton={hasCloseButton}
        defaultButton={defaultButton}
        isLoading={isLoading}
        showTitle={showTitle}
        customFooterInfoComponent={customInfoFooterComponent}
        customTitleComponent={customTitleComponent}
      >
        {layout}
      </ModalTour>
    )
  );
};
export default ApplyController;
