export const closeButtonName = {
  modal1: 'Agora não',
  modal2: 'Me pergunte depois',
  modal3: 'Agora não',
  modal4: 'Agora não',
  modal5: 'Me pergunte depois',
  modal6: 'Me pergunte mais tarde',
  modal7: 'Por enquanto não',
  modal8: 'Agora não, obrigado',
  modal9: 'Por enquanto não',
  modal10: 'Agora não, obrigado',
  modal11: 'Por enquanto não',
  top20StandOutModal: 'Agora não',
  bigLeapStandOutModal: 'Agora não',
  defaultStandOutModal: 'Agora não',
};

export const title = {
  modal1: 'Parabéns! Você enviou seu primeiro CV!',
  modal2: 'Seu CV foi enviado com sucesso!',
  modal3: 'Quer se destacar para esta vaga?',
  modal4: 'Quer ser visto primeiro pelo recrutador?',
  modal5: 'Quer ter mais destaque e ver menos anúncios?',
  modal6: 'Quer se destacar para esta vaga?',
  modal7: 'Seu CV foi enviado com sucesso!',
  modal8: 'Seu CV foi enviado com sucesso!',
  modal9: 'Seu CV foi enviado com sucesso!',
  modal10: 'Quer ter mais destaque para o recrutador?',
  modal11: 'Seu CV foi enviado com sucesso!',
  top20StandOutModal: 'Pule na frente dos concorrentes desta vaga',
  bigLeapStandOutModal: 'Quanto destaque você pode conseguir?',
  defaultStandOutModal: 'Deixe seu CV no topo do ranking desta vaga',
};

export const getCTAButtonName = () => {

  return {
    modal1: 'Quero ter <b>mais destaque</b>',
    modal2: 'Quero ficar em <b>destaque</b>',
    modal3: 'Quero <b>saber mais</b>',
    modal4: 'Quero <b>me destacar</b>',
    modal5: 'Quero saber mais',
    modal6: 'Quero <b>ficar na frente</b>',
    modal7: 'Quero <b>mais destaque</b>',
    modal8: 'Me mostre o <b>#plan</b>',
    modal9: 'Sim, quero <b>me destacar</b>',
    modal10: 'Me mostre como',
    modal11: 'Quero aparecer em <b>destaque</b>',
    top20StandOutModal:'<b>Pular</b> na frente',
    bigLeapStandOutModal: 'Saltar <b>#balance</b> posições <b>agora</b>',
    defaultStandOutModal: 'Subir no ranking',
  };
}

export const template = (text, { rankPosition, targetPlanName }) => {
  let updatedText = text;
  if (text && rankPosition) {
    updatedText = text.replace('#balance', rankPosition.balance);
  }
  if (text && targetPlanName) {
    updatedText = updatedText.replace('#plan', targetPlanName);
  }
  return updatedText;
};

